
import {defineComponent,nextTick,onBeforeMount,onMounted,reactive, ref,toRefs,} from "vue";
import dayjs, { Dayjs } from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import "dayjs/locale/zh-cn";
import * as echarts from "echarts";
import DateUtil, { getPickerTime } from "@/utils/common";
import { getCardList, getNewMemberData, getChartData ,getOrderData} from "@/api/saleManage";
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
export default defineComponent({
  name: "sale",
  components:{
    breadcrumb
  },
  setup() {
    const isOpen = ref<boolean>(false)
    // 面包屑
    const breadData = ref<Array<object>>([
      {title:'首页',path:'#/'},
      {title:'个人',path:'/index'},
      {title:'我的销售',path:'#/'},
    ])

    // card
    const cardList = reactive({
      list: [
        { title: "用户数" },
        { title: "成交量（二维码+实体卡+自然流量）" },
        { title: "二维码" },
        { title: "实体卡" },
        { title: "体验会员" },
        { title: "自然流量" },
      ],
      cardData: {},
    });
    // picker
    const time = ref<Dayjs[]>();
    const timeDate = reactive({
      type: "",
      time: {},
    });
    const pickerFoo = ref<Array<any>>([
      { title: "清空", type: "0" },
      { title: "今天", type: "1" },
      { title: "本周", type: "2" },
      { title: "当月", type: "3" },
      { title: "今年", type: "4" },
      { title: "去年", type: "5" },
    ]);
    // table
    const data = ref<Array<any>>([]);
    const columns = [
      {title: '时间',dataIndex: 'date_time',key: 'date_time',},
      { title: '总收益', dataIndex: 'totle', key: 'totle',},
      {title: '二维码',dataIndex: 'ewm_count', key: 'ewm_count',width:'40%'},
      {title: '实体卡', dataIndex: 'card_count',key: 'card_count',},
      { title: '自然流量',dataIndex: 'flow_count',key: 'flow_count',width:'20%'},
      {title: '体验会员',dataIndex: 'exper_count',key: 'exper_count',},
    ];

    // chart
    const leftXData = ref<Array<string>>([]);
    const leftYData = ref<Array<number>>([]);
    const rightXData = ref<Array<any>>([]);
    const rightYData = ref<Array<any>>([]);

    onBeforeMount(() => {
      let role_id = localStorage.getItem('userInfo')
      role_id = JSON.parse(role_id as string)
      if((role_id as any).role_id == 5){
        breadData.value.splice(2,1)
      }
      
      setPicker('3')
      getData();
      getLeftChart();
      chartInitData()
      getOrderTableData();
    });

    const leftInit = () => {
      var myChart = echarts.init((document as any).getElementById("left"));
      var option;
      option = {
        // title: {
        //     text: '| 新增会员',
        //     textStyle:{
        //         color:'#FFC972'
        //     }
        // },
        backgroundColor: "#282976",
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: leftXData.value,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#30328D",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        series: [
          {
            name: "Line 4",
            type: "line",
            // stack: "Total",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "#5081FF",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(80,129,255,0.2)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: leftYData.value,
          },
        ],
      };

      myChart.setOption(option);
    };
    const rightInit = () => {
      var myChart = echarts.init((document as any).getElementById("right"));
      let data: Array<string> = [
        "总计",
        "购买数",
        "二维码",
        "实体卡",
        "体验会员",
        "自然流量",
      ];
      let color: Array<string> = [
        "#5081FF",
        "#703AB7",
        "#CA5A40",
        "#67BAC3",
        "#58A55C",
        "#D0A537",
      ];
      let legendData: Array<object> = [];
      let seriesData: Array<object> = [];
      for (let i = 0; i < data.length; i++) {
        legendData.push({
          name: data[i],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 5,
          },
        });
        seriesData.push({
          name: data[i],
          type: "line",
          // stack: "Total",
          showSymbol: false,
          data: rightYData.value[i],
          lineStyle: {
            color: color[i],
          },
          symbolSize: 10,
          symbol: "circle",
          smooth: true,
        });
      }
      var option;
      option = {
        color: color,
        backgroundColor: "#282976",
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#7471A7",
          },
          top: 20,
          right: 20,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: rightXData.value,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#30328D",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        series: seriesData,
      };
      myChart.setOption(option);
    };

    // picker底部点击事件
    const setPicker = (type: string): void => {
      timeDate.type = type;
      switch (type) {
        case "0":
          time.value = [];
          break;
        case "1":
          time.value = [dayjs(), dayjs()];
          break;
        case "2":
          time.value = [
            dayjs(DateUtil.DateUtil.getStartDayOfWeek()),
            dayjs(DateUtil.DateUtil.getEndDayOfWeek()),
          ];
          break;
        case "3":
          time.value = [
            dayjs(DateUtil.DateUtil.getStartDayOfMonth()),
            dayjs(DateUtil.DateUtil.getEndDayOfMonth()),
          ];
          break;
        case "4":
          time.value = [
            dayjs(DateUtil.DateUtil.getYear("s", 0)),
            dayjs(DateUtil.DateUtil.getYear("e", 0)),
          ];
          break;
        case "5":
          time.value = [
            dayjs(DateUtil.DateUtil.getYear("s", -1)),
            dayjs(DateUtil.DateUtil.getYear("e", -1)),
          ];
          break;
        default:
          break;
      }

      timeDate.time = getPickerTime(time.value, 0);
    };
    // 时间框的回调
    const timeChange = (): void => {
      timeDate.type = "6";
      timeDate.time = getPickerTime(time.value, 0);
    };

    const close = (): void => {
      isOpen.value = false;
      getData();
      getLeftChart();
      chartInitData()
      getOrderTableData();
    };

    // card 数据
    const getData = () => {
      getCardList({
        start_time: (timeDate.time as any).start,
        my_order: 1,
        end_time: (timeDate.time as any).end,
        type: timeDate.type,
      }).then((res: any) => {
        if (res.code == 200) {
          cardList.cardData = res.data;
        }
      });
    };
    // 新增会员数据
    const getLeftChart = (): void => {
      getNewMemberData({
        start_time: (timeDate.time as any).start,
        my_order: 1,
        end_time: (timeDate.time as any).end,
        type: timeDate.type,
      }).then((res: any) => {
        if (res.code == 200) {
          leftXData.value = res.data.data_xtime;
          leftYData.value = res.data.data_echarts;
          leftInit();
        }
      });
    };
    // 获取图标数据
    const chartInitData = (): void => {
      getChartData({
        start_time: (timeDate.time as any).start,
        my_order: 1,
        end_time: (timeDate.time as any).end,
        type: timeDate.type,
      }).then((res: any) => {
        if (res.code == 200) {
          rightXData.value = res.data.data_xtime;
          rightYData.value = res.data.data_echarts;
          rightInit();
        }
      });
    };
    // 订单数据
    const getOrderTableData = ():void =>{
      getOrderData({start_time:(timeDate.time as any).start,my_order:1, end_time:(timeDate.time as any).end,
        type:timeDate.type}).then((res:any)=>{
          if(res.code == 200){
            data.value = res.data.data_order_totle;
          }
      })
    }
    // 展开
    const pickerFocus = ()=>{
        isOpen.value = true;
    }

    // 收起时间框
    const openChange = (status:boolean):void =>{
      isOpen.value = status;
    }

    return {
      locale,
      time,
      ...toRefs(cardList),
      data,
      columns,
      pickerFoo,
      setPicker,
      timeChange,
      close,
      pickerFocus,
      openChange,
      breadData,
      isOpen
    };
  },
});
